<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row>
        <v-col cols="12">
          <h1 class="title-main">Users</h1>
        </v-col>
        <v-col>
          <admin-users-table></admin-users-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import AdminUsersTable from "./Widgets/AdminUsersTable.vue";

export default {
  name: "Users",
  data() {
    return {
    }
  },
  components: {
    AdminUsersTable,
  },
};
</script>
