<template>
  <v-card class="card-shadow">
    <v-row>
      <v-col cols="12">
        <div class="card-header-padding card-border-bottom">
          <p class="font-weight-600 text-h3 text-typo mb-0">List of users</p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="px-4">
          <v-text-field
          hide-details
          class="input-style font-size-input text-light-input placeholder-light input-icon"
          dense
          flat
          filled
          solo
          height="43"
          placeholder="Search"
          @input="setPage(1)"
          v-model="search">
            <template slot="prepend-inner">
              <v-icon color="#adb5bd" size=".875rem"
                >fas fa-search</v-icon
              >
            </template>
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <div class="d-flex justify-end px-4">
          <v-btn-toggle v-model="toggle_exclusive" class="btn-toggle ml-auto">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-secondary py-3 px-6 border-0"
              color="#f7fafc"
            >
              Requested status
            </v-btn>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-secondary py-3 px-6 border-0"
              color="#f7fafc"
              >
              All users
            </v-btn>
          </v-btn-toggle>

        </div>
      </v-col>
    </v-row>
    <div class="py-3"></div>
    <v-card-text class="px-0 py-0">
      <v-simple-table class="table custom-table">
        <template v-slot:default>
          <thead class="thead-light">
            <tr>
              <th class="text-left ps-6">
                Email
              </th>

              <th v-if="haveBalance" class="text-left ps-6">
                Amount
              </th>
              <th class="text-left">
                User Profile
              </th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="(item) in displayedUsers.slice(pageStart, pageStart + usersPerPage)">
              <td class="border-bottom">
                <span class="text-body text-h5">
                  <span class="text-body text-h5">
                    {{ item.email }}
                  </span>
                </span>
              </td>

              <td v-if="haveBalance" class="border-bottom">
                <span class="text-body text-h5">
                  <span class="text-body text-h5">
                    {{ formatCurrency7(item.balance) }}
                  </span>
                </span>
              </td>

              <td class="border-bottom">
                <input type="hidden" value="item.id">
                <input type="hidden" value="item.name">
                <v-btn
                  @click="loginIntoUser(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls me-2 my-2 rounded-sm"
                  color="transparent"
                >
                  <v-icon color="#7D8D9F" size="16">ni ni-single-02</v-icon>
                </v-btn>
              </td>
            </tr>

            <tr v-if="displayedUsers.length == 0 && (search != '' || showUsersWithRequestedPayoutsButtonIsActive)">
              <td colspan="3" class="text-center text-light">
                No matching records found
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <div v-if="displayedUsers.length > 10" class="card-padding d-flex justify-end">
      <nav aria-label="Table navigation">
        <ul class="d-flex justify-content-end custom-pagination">
          <li 
          class="page-item">
          <v-btn
            class="mx-2 custom-pagination__button"
            :class="{'disabled': (currPage === 1)}" 
            width="40"
            height="40"
            @click.prevent="setPage(1)">
            <i aria-hidden="true" class="v-icon notranslate fa fa-angle-left theme--light"></i>
            <i aria-hidden="true" class="v-icon notranslate fa fa-angle-left theme--light"></i>
          </v-btn>
          </li>
          <li 
          class="page-item">
          <v-btn
            class="mx-2 custom-pagination__button"
            :class="{'disabled': (currPage === 1)}" 
            width="40"
            height="40"
            @click.prevent="setPage(currPage-1)">
            <i aria-hidden="true" class="v-icon notranslate fa fa-angle-left theme--light"></i>
          </v-btn>
          </li>
          <li class="page-item" v-for="n in paginationPages">
          <v-btn
            class="mx-2 custom-pagination__button"
            width="40"
            height="40"
            v-bind:class="{'custom-pagination__button--active': (currPage === (n))}" 
            @click.prevent="setPage(n)">
              {{n}}
            </v-btn>
          </li>
          <li 
          class="page-item">
            <v-btn
              class="mx-2 custom-pagination__button"
              :class="{'disabled': (currPage === totalPage)}" 
              width="40"
              height="40"
              @click.prevent="setPage(currPage+1)">
              <i aria-hidden="true" class="v-icon notranslate fa fa-angle-right theme--light"></i>
            </v-btn>
          </li>
          <li 
          class="page-item">
            <v-btn
              class="mx-2 custom-pagination__button"
              :class="{'disabled': (currPage === totalPage)}" 
              width="40"
              height="40"
              @click.prevent="setPage(totalPage)">
              <i aria-hidden="true" class="v-icon notranslate fa fa-angle-right theme--light"></i>
              <i aria-hidden="true" class="v-icon notranslate fa fa-angle-right theme--light"></i>
            </v-btn>
          </li>
        </ul>
      </nav>
    </div>
  </v-card>
</template>
<script>

import {getAdminUserList} from '@/data.js';

export default {
  name: "admin-users-table-test",
  data() {
    return {
      //rows: window.appData.adminData.users,
      allUsers: [],

      page: 1,
      usersPerPage: 10,
      currPage: 1,
      toggle_exclusive: 1,
      search: '',
      haveBalance: false,
    };
  },
  computed: {
    showUsersWithRequestedPayoutsButtonIsActive: function() {
      //"Requested Status" button is checked
      //"All Users" will have value 1
      return this.toggle_exclusive == 0;
    },
    displayedUsers: function() {
      const search = this.search.toLowerCase();
      let users = this.allUsers;

      if (this.haveBalance) {
        users.sort(function(a, b) {
          return -(a.balance - b.balance);
        });
      } else {
        users.sort(function(a, b) {
          return a.email.localeCompare(b.email);
        });
      }

      if (this.showUsersWithRequestedPayoutsButtonIsActive) {
        users = users.filter(function (user) {
          return user.hasUnprocessedPayouts;
        });
      }

      let result = [];
      if (this.search.trim() !== '') {
        result = users.filter(function(user) {
          return user.email.toLowerCase().indexOf(search) > -1;
        });
      } else {
        result = users;
      }
      return result;
    },
    pageStart: function(){
      return (this.currPage - 1) * this.usersPerPage;
    },
    totalPage: function(){
      return Math.ceil(this.displayedUsers.length/this.usersPerPage);
    },
    paginationPages: function(){
      this.setPages();
      return this.pages;
    },
  },
  methods: {
    setPages: function(){
      this.pages = [];
      let numberOfPages = Math.ceil(this.displayedUsers.length/this.usersPerPage);
      if(numberOfPages <= 5) {
        for (let i = 1; i <= numberOfPages; i++) {
          this.pages.push(i);
        }
      } else if(this.currPage === 1) {
        this.pages = [this.currPage, this.currPage + 1, this.currPage + 2, this.currPage + 3, this.currPage + 4];
      } else if(this.currPage === 2) {
        this.pages = [this.currPage - 1, this.currPage, this.currPage + 1, this.currPage + 2, this.currPage + 3];
      } else if(this.currPage === numberOfPages) {
        this.pages = [this.currPage - 4, this.currPage - 3, this.currPage - 2, this.currPage - 1, this.currPage];
      } else if(this.currPage === numberOfPages - 1) {
        this.pages = [this.currPage - 3, this.currPage - 2, this.currPage - 1, this.currPage, this.currPage + 1];
      } else {
        this.pages = [this.currPage - 2, this.currPage - 1, this.currPage, this.currPage + 1, this.currPage + 2];
      }
    },
    formatCurrency7(value) {
      if (value == 0) return `$ 0.0000000`;
      return `$ ${value.toFixed(7)}`;
    },
    formatDate(value) {
      const year = value.getFullYear();
      let month = value.getMonth();
      month = String(month).length > 1 ? month : `0${month}`;
      let date = value.getDate();
      date = String(date).length > 1 ? date : `0${date}`;
      const newDate = `${date}.${month}.${year}`;
      return newDate;
    },
    setPage: function(idx){
      if( idx <= 0 || idx > this.totalPage ){
        return;
      }
      this.currPage = idx;
    },
    loginIntoUser(item) {
      this.$router.push({name: 'AdminAsUserDashboard', params: {userId: item.uid}})
    },
  },

  created: async function () {
    this.allUsers = (await getAdminUserList());
  },
};
</script>
